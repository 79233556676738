<template>
  <div class="tabs">
    <div
      class="item"
      :class="{ active: index === tabIndex }"
      v-for="(item, index) of tabs"
      :key="item.id"
      @click="handleItemClick(index)"
    >
      {{ item.name }}
    </div>
  </div>
</template>
<script>
export default {
  name: "Tabs",
  data() {
    return {
      tabIndex: 0,
      tabs: [
        {
          value: 0,
          name: "已提交",
        },
        {
          value: 1,
          name: "已审核",
        },
        {
          value: 2,
          name: "待打印",
        },
        {
          value: 3,
          name: "生产中",
        },
        {
          value: 4,
          name: "生产完成",
        },
        {
          value: 5,
          name: "已发货",
        },
        {
          value: 6,
          name: "问题件",
        },
        {
          value: null,
          name: "所有订单",
        },
      ],
    };
  },
  methods: {
    handleItemClick(index) {
      this.tabIndex = index;
      this.$emit("status", this.tabs[index].value);
    },
  },
};
</script>
<style lang="less" scoped>
.tabs {
  display: flex;
  background-color: #f5f7fa;
  border-bottom: 1px solid #e4e7ed;
  .item {
    padding: 0 20px;
    line-height: 40px;
    box-sizing: border-box;
    border: 1px solid transparent;
    margin-top: -1px;
    color: #909399;
    cursor: pointer;
    overflow: hidden;
    &.active {
      background-color: #fff;
      border-right-color: #dcdfe6;
      border-left-color: #dcdfe6;
    }
  }
}
</style>