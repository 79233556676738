<template>
  <table-container :list="list">
    <el-table-column type="index"> </el-table-column>
    <el-table-column prop="name" label="材质"> </el-table-column>
    <el-table-column prop="name" label="型号"> </el-table-column>
    <el-table-column prop="name" label="买家昵称"> </el-table-column>
    <el-table-column prop="name" label="订单号"> </el-table-column>
    <el-table-column prop="name" label="快递公司"> </el-table-column>
    <el-table-column prop="mcode" label="订单图片"> </el-table-column>
    <el-table-column prop="mcode" label="客服人员"> </el-table-column>
    <el-table-column prop="mcode" label="店铺"> </el-table-column>
    <el-table-column prop="mcode" label="状态"> </el-table-column>
    <el-table-column prop="mcode" label="备注"> </el-table-column>
    <el-table-column prop="mcode" label="提交时间"> </el-table-column>
    <el-table-column prop="mcode" label="操作"> </el-table-column>
  </table-container>
</template>
<script>
export default {
  name: "List",
  props: {
    list: Array,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="less" scoped>
</style>