<template>
  <div class="order-list">
    <order-tabs @status="handleStatusChange" />
    <order-nav @change="handleFilter" />
    <list :list="list" />
    <el-pagination
      background
      layout="total,prev, pager, next,sizes"
      :page-size="pageSize"
      :page-sizes="pageSizes"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleChangePage"
    />
  </div>
</template>
<script>
import OrderTabs from "./components/Tabs";
import OrderNav from "./components/Nav";
import List from "./components/List";
export default {
  name: "OrderList",
  components: {
    OrderTabs,
    OrderNav,
    List,
  },
  data() {
    return {
      list: [],
      status: 0,
      startTime: null,
      endTime: null,
      express_ids: null,
      bHandleOrder: null,
      agent_ids: null,
      stuff_ids: null,
      type_ids: null,
      total: 0,
      page: 0,
      pageSize: 10,
      pageSizes: [10, 20, 30, 40, 50],
    };
  },
  methods: {
    async handleFilterOrderItems() {
      const data = {
        status: this.status,
        startTime: this.startTime,
        endTime: this.endTime,
        express_ids: this.express_ids,
        bHandleOrder: this.bHandleOrder,
        agent_ids: this.agent_ids,
        stuff_ids: this.stuff_ids,
        type_ids: this.type_ids,
        page: this.page,
        pageSize: this.pageSize,
      };
      const res = await this.$store.dispatch("filterOrderItems", data);
      this.list = res.items;
      this.total = res.total;
    },
    //状态改变
    handleStatusChange(status) {
      this.status = status;
      this.handleFilterOrderItems();
    },
    //筛选
    handleFilter(data) {
      const {
        startTime,
        endTime,
        express_ids,
        bHandleOrder,
        agent_ids,
        stuff_ids,
        type_ids,
      } = data;
      this.startTime = startTime ? startTime : null;
      this.endTime = endTime ? endTime : null;
      this.express_ids = express_ids ? express_ids : null;
      this.bHandleOrder = bHandleOrder;
      this.agent_ids = agent_ids ? agent_ids : null;
      this.stuff_ids = stuff_ids ? stuff_ids : null;
      this.type_ids = type_ids ? type_ids : null;
      this.handleFilterOrderItems();
    },
    handleSizeChange() {},
    handleChangePage() {},
  },
};
</script>
<style lang="less" scoped>
</style>