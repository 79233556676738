<template>
  <div class="nav">
    <el-row :gutter="20">
      <el-col :span="3">
        <el-select filterable v-model="modelId" placeholder="请选择模型">
          <el-option
            v-for="item in modelList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-col>

      <el-col :span="5">
        <el-input
          placeholder="请输入订单号"
          v-model="searchVal"
          class="input-with-select"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="handleSearch"
          ></el-button>
        </el-input>
      </el-col>
    </el-row>
    <el-row class="filter-row" :gutter="20">
      <el-col :span="3">
        <el-select v-model="bHandleOrder">
          <el-option
            v-for="item in bHandleOrderList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        <el-select
          filterable
          clearable
          v-model="stuff_ids"
          placeholder="选择材质"
          multiple
          collapse-tags
        >
          <el-option
            v-for="item in stuffList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        <el-select
          v-model="brandIds"
          multiple
          collapse-tags
          placeholder="手机品牌"
        >
          <el-option
            v-for="item in brandList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        <el-select
          v-model="type_ids"
          multiple
          collapse-tags
          placeholder="手机型号"
        >
          <el-option
            v-for="item in typeList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-col>

      <el-col :span="3">
        <el-select
          v-model="agentTypeIds"
          multiple
          collapse-tags
          placeholder="代理类型"
        >
          <el-option
            v-for="item in agentTypeList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        <el-select
          v-model="levelIds"
          multiple
          collapse-tags
          placeholder="代理级别"
        >
          <el-option
            v-for="item in levelList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        <el-select
          v-model="agent_ids"
          multiple
          collapse-tags
          placeholder="代理"
        >
          <el-option
            v-for="item in agentList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        <el-select
          v-model="express_ids"
          multiple
          collapse-tags
          placeholder="快递"
        >
          <el-option
            v-for="item in expressList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-col>

      <el-col :span="6">
        <el-date-picker
          v-model="date"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-col>
      <el-col :span="2">
        <el-button type="primary" @click="handleFilterClick">筛选</el-button>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { mapState } from "vuex";
import AllSelect from "@/components/AllSelect";
import { dateShortcuts, toDaytime } from "@/util/shortcuts";
export default {
  name: "Nav",
  props: {
    // modelList: Array,
    // brandList: Array,
  },
  components: { AllSelect },
  data() {
    return {
      initLock: false, //初始化锁
      searchVal: "",
      modelId: null,
      stuff_ids: [],
      brandIds: [],
      typeList: [],
      type_ids: [],
      allBrandId: [],
      agentTypeIds: [],
      levelIds: [],
      agent_ids: [],
      agentTypeList: [],
      levelList: [],
      agentList: [],
      express_ids: [],
      expressList: [],
      bHandleOrder: "1",
      bHandleOrderList: [
        {
          id: "1",
          name: "非手工单",
        },
        {
          id: "2",
          name: "手工单",
        },
      ],
      date: toDaytime,
      startTime: "",
      endTime: "",
      pickerOptions: {
        shortcuts: dateShortcuts,
      },
    };
  },
  computed: {
    ...mapState(["modelList", "brandList", "stuffList"]),
  },
  watch: {
    date(e) {
      if (e) {
        this.startTime = Math.floor(e[0] / 1000);
        this.endTime = Math.floor(e[1] / 1000);
      }
    },
    brandIds() {
      this.getTypeList();
    },
    agentTypeIds() {
      this.getAgentList();
    },
    levelIds() {
      this.getAgentList();
    },
    async modelList() {
      this.modelId = this.modelList[0].id;
    },
  },
  created() {
    this.handleInit();
  },
  methods: {
    //初始化
    async handleInit() {
      if (this.date) {
        this.startTime = Math.floor(this.date[0] / 1000);
        this.endTime = Math.floor(this.date[1] / 1000);
      }
      await this.$store.dispatch("getGoodsModels");
      await this.$store.dispatch("getAllBrandList");
      this.getAgentTypeList();
      this.getAgentLevelList();
      this.handleChange();
      this.getAllExpress();
      await this.$store.dispatch("getAllStuff");
      this.handleFilterClick();
    },
    //筛选按钮
    handleFilterClick() {
      const data = {
        startTime: this.startTime,
        endTime: this.endTime,
        express_ids: JSON.stringify(this.express_ids),
        bHandleOrder: this.bHandleOrder,
        agent_ids: JSON.stringify(this.agent_ids),
        stuff_ids: JSON.stringify(this.stuff_ids),
        type_ids: JSON.stringify(this.type_ids),
      };
      this.$emit("change", data);
    },
    //获取代理类型列表
    async getAgentTypeList() {
      const res = await this.$store.dispatch("getAgentTypeList");
      this.agentTypeList = res.items;
    },
    //获取代理等级列表
    async getAgentLevelList() {
      const res = await this.$store.dispatch("getAgentLevelList");
      this.levelList = res.items;
    },
    //获取代理列表
    async getAgentList() {
      const agent_level_id = this.levelIds.join(",");
      const agent_type_id = this.agentTypeIds.join(",");
      const res = await this.$store.dispatch("getAgentList", {
        agent_level_id,
        agent_type_id,
      });
      this.agentList = res.items;
    },
    //获取快递列表
    async getAllExpress() {
      const res = await this.$store.dispatch("getAllExpress");
      this.expressList = res.items;
    },
    //选择时间
    handleChange() {
      this.$emit("change", {
        startTime: this.startTime,
        endTime: this.endTime,
      });
    },

    //获取型号列表
    async getTypeList() {
      const res = await this.$store.dispatch("getBrandFindType", {
        brand_id: JSON.stringify(this.brandIds),
      });
      this.typeList = res.data;
    },
    handleCheckedBrand(param) {
      this.brandIds = param;
      this.getTypeList();
    },
    //新增
    handleAddSpec() {
      this.$emit("add");
    },
    //搜索
    handleSearch() {
      this.$emit("search", this.searchVal);
    },
  },
};
</script>
<style lang="less" scoped>
.nav {
  margin-top: 10px;
}
.el-row {
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
}
.filter-row {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  .el-col {
    margin-bottom: 10px;
  }
}
</style>
